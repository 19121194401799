/* eslint-disable no-undef */
const ConsentInspector = (function () {
  function getConsent() {
    this.CONSENT_COOKIE = 'consent';
    this.REQUIRED_COOKIES = [this.CONSENT_COOKIE, '_optisure_session'];
    const existingConsent = document.cookie
      .split('; ')
      .find((row) => row.startsWith(`${this.CONSENT_COOKIE}=`));
    if (existingConsent) {
      try {
        return JSON.parse(existingConsent.split('=')[1]);
      } catch {
        /* eslint-disable-next-line no-empty */
      }
    }
  }

  async function latestConsentVersion() {
    const response = await window.fetch('/api/consent/version', {
      headers: {
        Accept: 'application/vnd.clark-v5+json',
        'Content-Type': 'application/json',
      },
    });
    if (response.ok) {
      const json = await response.json();
      if (json && json.data && json.data.attributes) {
        return json.data.attributes.version;
      }
    }
  }

  function categoryExist(categoryIdent, consent) {
    return consent.categories.some(
      (category) => category.identifier === categoryIdent,
    );
  }

  function categoryEnabled(categoryIdent, consent) {
    category = consent.categories.find(
      (category) => category.identifier === categoryIdent,
    );
    return category && category.enabled;
  }

  function isEligible(categoryIdent, consent) {
    return (
      categoryExist(categoryIdent, consent) &&
      categoryEnabled(categoryIdent, consent)
    );
  }

  function getDomainWithSubdomains() {
    return window.location.hostname;
  }

  function getDomain() {
    return getDomainWithSubdomains().split('.').slice(-2).join('.');
  }

  function cookieNamesStartsWith(cookieName) {
    return document.cookie
      .split(';')
      .map((name) => name.trim())
      .filter((name) => name.startsWith(cookieName))
      .map((name) => name.split('=')[0]);
  }

  function filteredCookieNames(cookieNamePrefix) {
    return cookieNamesStartsWith(cookieNamePrefix).filter(
      (name) => !this.REQUIRED_COOKIES.includes(name.split('=')[0]),
    );
  }

  function cleanUpCookies(cookiePrefixes) {
    const anyUTCDateInPast = 'Thu, 01 Jan 1970 00:00:00 UTC';
    cookiePrefixes.forEach((prefix) => {
      filteredCookieNames(prefix).forEach((name) => {
        document.cookie = `${name}=; expires="${anyUTCDateInPast}"; path=/; domain=.${getDomain()}`;
        document.cookie = `${name}=; expires="${anyUTCDateInPast}"; path=/; domain=.${getDomainWithSubdomains()}`;
      });
    });
  }

  function sessionStorageItemsStartsWith(prefix) {
    const storageItems = [];
    for (let i = 0; i < sessionStorage.length; i++) {
      const item = sessionStorage.key(i);
      if (item.startsWith(prefix)) {
        storageItems[i] = item;
      }
    }
    return storageItems;
  }

  function localStorageItemsStartsWith(prefix) {
    const storageItems = [];
    for (let i = 0; i < localStorage.length; i++) {
      const item = localStorage.key(i);
      if (item.startsWith(prefix)) {
        storageItems[i] = item;
      }
    }
    return storageItems;
  }

  function cleanUpStorages(storageItemsPrefixes) {
    storageItemsPrefixes.forEach((prefix) => {
      localStorageItemsStartsWith(prefix).forEach((storageItem) => {
        localStorage.removeItem(storageItem);
      });
      sessionStorageItemsStartsWith(prefix).forEach((storageItem) => {
        sessionStorage.removeItem(storageItem);
      });
    });
  }

  return {
    async featureEnabled() {
      const version = await latestConsentVersion();
      return version !== undefined;
    },

    executePerConsent(
      categoryIdent,
      cookiePrefixes,
      storageItems,
      onConsentCallback,
    ) {
      const consent = getConsent();
      const featureActive = true;
      if (featureActive) {
        if (consent !== undefined && isEligible(categoryIdent, consent)) {
          onConsentCallback();
        } else {
          cleanUpCookies(cookiePrefixes);
          cleanUpStorages(storageItems);
        }
      } else {
        onConsentCallback();
      }
    },
  };
})();

window.ConsentInspector = ConsentInspector;
